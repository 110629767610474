<template>
  <button @click="exportExcel()">
    <img src="@/assets/Export.svg" />
    <p>Export</p>
  </button>
</template>
<script>
import httpAxios from '@/utils/http-axios';
export default {
  props: ['model'],
  setup(props) {
    const exportExcel = () => {
      httpAxios({
        url: 'configurations/imports/' + props.model,
        method: 'GET',
      }).then((response) => {
        const base64 = response.data.data.base64;
        const filename = response.data.data.filename;

        const anchor = document.createElement('a');
        anchor.setAttribute('download', filename);
        anchor.setAttribute('href', base64);
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
      });
    };

    return { exportExcel };
  },
};
</script>
<style scoped>
button {
  display: flex !important;
  width: 130px !important;
  height: 40px !important;
  background: #1d2225 !important;
  gap: 10px !important;
  border-radius: 10px !important;
  border: 1px solid #4d4d4d !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 14px !important;
  color: white !important;
}
</style>
