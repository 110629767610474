import store from '@/store';
const modul = {mounted: (el, binding) => {
    let modules = store.getters.getModules;

    if(!modules) {
        el.remove();
        return;
    }

    const check = modules.filter((e) => {
        return e.modul.module_name == binding.value
    })[0] ?? null;

    if(!check) {
        el.remove()
    }
}}
export { modul }