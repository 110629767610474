export default [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home',
    component: () => import('@/modules/Client/Home'),
    name: 'home',
    meta: { guest: true },
  },
  {
    path: '/pricing',
    component: () => import('@/modules/Client/Pricing'),
    name: 'pricing',
    meta: { guest: true },
  },
  {
    path: '/login',
    component: () => import('@/modules/Client/Login'),
    name: 'admin.login',
    meta: { guest: true },
  },
  {
    path: '/register',
    component: () => import('@/modules/Client/Register'),
    name: 'admin.register',
    meta: { guest: true },
  },
  {
    path: '/unauthorized',
    component: () => import('@/modules/Error/401.vue'),
    name: 'error401',
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/modules/Error'),
    name: 'error404',
  },
];
