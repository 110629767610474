<template>
    <div v-if="data && data.data && data.data.length">
        <vue-awesome-paginate
            :total-items="data.total"
            :current-page="data.current_page"
            :items-per-page="parseInt(data.per_page)"
            :max-pages-shown="6"
            :on-click="update"
          >
            <template #prev-button>
              <span>
                <i class="fa-solid fa-arrow-left"></i>
              </span>
            </template>
            <template #next-button>
              <span>
                <i class="fa-solid fa-arrow-right"></i>
              </span>
            </template
          ></vue-awesome-paginate>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            required: true
        },
    },
    emits: ['update'],
    setup(props, { emit }) {
        const update = (page) => {
            emit('update', page)
        }

        return {
            update
        }
    },
}
</script>