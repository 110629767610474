export default [
  // Dashboard
  {
    path: '/admin/dashboard',
    component: () => import('@/modules/Admin/Dashboard'),
    name: 'admin.dashboard',
    meta: { auth: true },
  },
  // Articles
  {
    path: '/admin/articles',
    component: () => import('@/modules/Admin/Articles/Index'),
    name: 'admin.articles',
    meta: {
      auth: true,
      middleware: 'Articles',
    },
  },

  {
    path: '/admin/articles/new/:id',
    component: () => import('@/modules/Admin/Articles/NewArticle'),
    name: 'admin.newarticlesEdit',
    meta: {
      auth: true,
      middleware: 'Articles',
    },
  },

  // Update Stock
  {
    path: '/admin/core_stock',
    component: () => import('@/modules/Admin/CoreStock'),
    name: 'admin.coreStock',
    meta: {
      auth: true,
      middleware: 'Stock',
    },
  },

  //Subjects
  {
    path: '/admin/subjects/:page?',
    component: () => import('@/modules/Admin/Subjects/Index'),
    name: 'admin.subjects',
    meta: {
      auth: true,
      middleware: 'Subjects'
    },
  },
  //classifications-sub
  {
    path: '/admin/subjects/subclassifications',
    component: () => import('@/modules/Admin/Subjects/Classifications'),
    name: 'admin.subjects.subclassifications',
    meta: {
      auth: true,
      middleware: 'Subjects'
    },
  },

  //paymentMethod
  {
    path: '/admin/subjects/paymentmethod',
    component: () => import('@/modules/Admin/Subjects/PaymentMethod'),
    name: 'admin.subjects.paymentMethod',
    meta: {
      auth: true,
      middleware: 'Subjects'
    },
  },
  //banks
  {
    path: '/admin/subjects/banks',
    component: () => import('@/modules/Admin/Subjects/Banks'),
    name: 'admin.subjects.banks',
    meta: {
      auth: true,
      middleware: 'Subjects'
    },
  },
  //vehicles
  {
    path: '/admin/subjects/vehicles',
    component: () => import('@/modules/Admin/Subjects/Vehicles'),
    name: 'admin.subjects.vehicles',
    meta: {
      auth: true,
      middleware: 'Subjects'
    },
  },
  //locations
  {
    path: '/admin/subjects/locations',
    component: () => import('@/modules/Admin/Subjects/Locations'),
    name: 'admin.subjects.locations',
    meta: {
      auth: true,
      middleware: 'Subjects'
    },
  },
  //segments
  {
    path: '/admin/subjects/segments',
    component: () => import('@/modules/Admin/Subjects/Segments'),
    name: 'admin.subjects.segments',
    meta: {
      auth: true,
      middleware: 'Subjects'
    },
  },
  //DeliveryAddresses
  {
    path: '/admin/subjects/deliveryaddresses',
    component: () => import('@/modules/Admin/Subjects/DeliveryAddresses'),
    name: 'admin.subjects.deliveryAddresses',
    meta: {
      auth: true,
      middleware: 'Subjects'
    },
  },

  //Employees
  {
    path: '/admin/subjects/employees',
    component: () => import('@/modules/Admin/Subjects/Employees'),
    name: 'admin.subjects.employees',
    meta: {
      auth: true,
      middleware: 'Subjects'
    },
  },

  //TermsForBuyer
  {
    path: '/admin/subjects/termsforbuyer',
    component: () => import('@/modules/Admin/Subjects/TermsForBuyer'),
    name: 'admin.subjects.termsForBuyer',
    meta: {
      auth: true,
      middleware: 'Subjects'
    },
  },

  //Supplierconditions
  {
    path: '/admin/subjects/supplierconditions',
    component: () => import('@/modules/Admin/Subjects/Supplierconditions'),
    name: 'admin.subjects.supplierConditions',
    meta: {
      auth: true,
      middleware: 'Subjects'
    },
  },

  {
    path: '/admin/subjects/create',
    component: () => import('@/modules/Admin/Subjects/CreateEdit'),
    name: 'admin.subjects.createEdit',
    meta: {
      auth: true,
      middleware: 'Subjects'
    },
  },

  {
    path: '/admin/subjects/create/:id',
    component: () => import('@/modules/Admin/Subjects/CreateEdit'),
    name: 'admin.subjects.createEditId',
    meta: {
      auth: true,
      middleware: 'Subjects'
    },
  },

  //Projects
  {
    path: '/admin/articles/projects',
    component: () => import('@/modules/Admin/Articles/Projects'),
    name: 'admin.projects',
    meta: {
      auth: true,
      middleware: 'Articles',
    },
  },

  //Rebates Code
  {
    path: '/admin/articles/rebatesCode',
    component: () => import('@/modules/Admin/Articles/RebatesCode'),
    name: 'admin.rebatesCode',
    meta: {
      auth: true,
      middleware: 'Articles',
    },
  },

  //CodesOfRebate
  {
    path: '/admin/articles/codesOfRebate',
    component: () => import('@/modules/Admin/Articles/CodesOfRebate'),
    name: 'admin.codesOfRebate',
    meta: {
      auth: true,
      middleware: 'Articles',
    },
  },

  //CodesOfRebate
  {
    path: '/admin/articles/configurator',
    component: () => import('@/modules/Admin/Articles/Configurator'),
    name: 'admin.configurator',
    meta: {
      auth: true,
      middleware: 'Articles',
    },
  },

  //Manufacturers
  {
    path: '/admin/articles/manufacturers',
    component: () => import('@/modules/Admin/Articles/Manufacturers'),
    name: 'admin.manufacturers',
    meta: {
      auth: true,
      middleware: 'Articles',
    },
  },

  //communications
  {
    path: '/admin/subjects/communications',
    component: () => import('@/modules/Admin/Subjects/Communications'),
    name: 'admin.communications',
    meta: {
      auth: true,
      middleware: 'Subjects'
    },
  },

  //Cost Centres
  {
    path: '/admin/articles/costcentres',
    component: () => import('@/modules/Admin/Articles/CostCentres'),
    name: 'admin.costCentres',
    meta: {
      auth: true,
      middleware: 'Articles',
    },
  },

  //Types
  {
    path: '/admin/articles/types',
    component: () => import('@/modules/Admin/Articles/Types'),
    name: 'admin.types',
    meta: {
      auth: true,
      middleware: 'Articles',
    },
  },

  //TaxFees
  {
    path: '/admin/articles/taxfees',
    component: () => import('@/modules/Admin/Articles/TaxFees'),
    name: 'admin.taxfees',
    meta: {
      auth: true,
      middleware: 'Articles',
    },
  },

  //PricesList
  {
    path: '/admin/articles/priceslist',
    component: () => import('@/modules/Admin/Articles/PricesList'),
    name: 'admin.pricesList',
    meta: {
      auth: true,
      middleware: 'Articles',
    },
  },

  //Voucher
  {
    path: '/admin/articles/voucher',
    component: () => import('@/modules/Admin/Articles/Voucher'),
    name: 'admin.voucher',
    meta: {
      auth: true,
      middleware: 'Articles',
    },
  },

  //Variants
  {
    path: '/admin/articles/variants',
    component: () => import('@/modules/Admin/Articles/Variants'),
    name: 'admin.variants',
    meta: {
      auth: true,
      middleware: 'Articles',
    },
  },

  //Brands
  {
    path: '/admin/articles/brands',
    component: () => import('@/modules/Admin/Articles/Brands'),
    name: 'admin.brands',
    meta: {
      auth: true,
      middleware: 'Articles',
    },
  },

  //MeasurementUnits
  {
    path: '/admin/articles/MeasurementUnits',
    component: () => import('@/modules/Admin/Articles/MeasurementUnits'),
    name: 'admin.measurementUnits',
    meta: {
      auth: true,
      middleware: 'Articles',
    },
  },

  //Classifications
  {
    path: '/admin/articles/classifications',
    component: () => import('@/modules/Admin/Articles/Classifications'),
    name: 'admin.classifications',
    meta: {
      auth: true,
      middleware: 'Articles',
    },
  },

  //Advantages
  {
    path: '/admin/articles/Advantages',
    component: () => import('@/modules/Admin/Articles/Advantages'),
    name: 'admin.advantages',
    meta: {
      auth: true,
      middleware: 'Articles',
    },
  },

  {
    path: '/admin/articles/new',
    component: () => import('@/modules/Admin/Articles/NewArticle'),
    name: 'admin.newarticles',
    meta: {
      auth: true,
      middleware: 'Articles',
    },
  },
  // Services
  {
    path: '/admin/error',
    component: () => import('@/modules/Error'),
    name: 'admin.error',
    meta: {
      // auth: true
    },
  },

  //Import Purchases
  {
    path: '/admin/purchases/import',
    component: () => import('@/modules/Admin/Purchases/ImportPurchases'),
    name: 'admin.importPurchases',
    meta: {
      auth: true,
      middleware: 'Purchases'
    },
  },

  {
    path: '/admin/purchases/import/:id',
    component: () => import('@/modules/Admin/Purchases/ImportPurchases'),
    name: 'admin.importPurchasesEdit',
    meta: {
      auth: true,
      middleware: 'Purchases'
    },
  },

  //Local Purchases
  {
    path: '/admin/purchases/local',
    component: () => import('@/modules/Admin/Purchases/LocalPurchases'),
    name: 'admin.localPurchases',
    meta: {
      auth: true,
      middleware: 'Purchases'
    },
  },

  //Expense Purchases
  {
    path: '/admin/purchases/expense',
    component: () => import('@/modules/Admin/Purchases/ExpensePurchases'),
    name: 'admin.expensePurchases',
    meta: {
      auth: true,
      middleware: 'Purchases'
    },
  },

  //Expense Purchases
  {
    path: '/admin/purchases/expense/:id',
    component: () => import('@/modules/Admin/Purchases/ExpensePurchases'),
    name: 'admin.expensePurchasesEdit',
    meta: {
      auth: true,
      middleware: 'Purchases'
    },
  },

  //DebitNote Purchases
  {
    path: '/admin/purchases/debitnote',
    component: () => import('@/modules/Admin/Purchases/DebitNote'),
    name: 'admin.DebitNote',
    meta: {
      auth: true,
      middleware: 'Purchases'
    },
  },

  //DebitNote Purchases
  {
    path: '/admin/purchases/debitnote/:id',
    component: () => import('@/modules/Admin/Purchases/DebitNote'),
    name: 'admin.DebitNoteEdit',
    meta: {
      auth: true,
      middleware: 'Purchases'
    },
  },

  //Return Purchases
  {
    path: '/admin/purchases/return',
    component: () => import('@/modules/Admin/Purchases/Return'),
    name: 'admin.puReturn',
    meta: {
      auth: true,
      middleware: 'Purchases'
    },
  },

  //Return Purchases
  {
    path: '/admin/purchases/return/:id',
    component: () => import('@/modules/Admin/Purchases/Return'),
    name: 'admin.puReturnEdit',
    meta: {
      auth: true,
      middleware: 'Purchases'
    },
  },

  //ReverseLoad
  {
    path: '/admin/purchases/reverseload',
    component: () => import('@/modules/Admin/Purchases/ReverseLoad'),
    name: 'admin.reverseLoad',
    meta: {
      auth: true,
      middleware: 'Purchases'
    },
  },
  //ReverseLoad
  {
    path: '/admin/purchases/reverseload/:id',
    component: () => import('@/modules/Admin/Purchases/ReverseLoad'),
    name: 'admin.reverseLoadEdit',
    meta: {
      auth: true,
      middleware: 'Purchases'
    },
  },

  //Transit Purchases
  {
    path: '/admin/purchases/transit',
    component: () => import('@/modules/Admin/Purchases/TransitPurchases'),
    name: 'admin.transitPurchases',
    meta: {
      auth: true,
      middleware: 'Purchases'
    },
  },

  //From Farmers Purchases
  {
    path: '/admin/purchases/fromfarmers/:id?',
    component: () => import('@/modules/Admin/Purchases/FromFarmers'),
    name: 'admin.fromFarmers',
    meta: {
      auth: true,
      middleware: 'Purchases'
    },
  },

  //Accounting Book
  {
    path: '/admin/purchases/accountingbook',
    component: () => import('@/modules/Admin/Purchases/AccountingBook'),
    name: 'admin.accountingBook',
    meta: {
      auth: true,
      middleware: 'Purchases'
    },
  },

  //Cash Sales
  {
    path: '/admin/sales/cash/:id?',
    component: () => import('@/modules/Admin/Sales/CashSales'),
    name: 'admin.cashSales',
    meta: {
      auth: true,
      middleware: 'Sales'
    },
  },

  //Local Sales
  {
    path: '/admin/sales/local',
    component: () => import('@/modules/Admin/Sales/LocalSales'),
    name: 'admin.localSales',
    meta: {
      auth: true,
      middleware: 'Sales'
    },
  },

  //Local Sales Edit
  {
    path: '/admin/sales/local/:id',
    component: () => import('@/modules/Admin/Sales/LocalSales'),
    name: 'admin.localSalesEdit',
    meta: {
      auth: true,
      middleware: 'Sales'
    },
  },

  //Export Sales
  {
    path: '/admin/sales/export/:id?',
    component: () => import('@/modules/Admin/Sales/ExportSales'),
    name: 'admin.exportSales',
    meta: {
      auth: true,
      middleware: 'Sales'
    },
  },

  //Delivery Note
  {
    path: '/admin/sales/deliverynote/:id?',
    component: () => import('@/modules/Admin/Sales/DeliveryNote'),
    name: 'admin.deliveryNote',
    meta: {
      auth: true,
      middleware: 'Sales'
    },
  },

  //Retrun Sales
  {
    path: '/admin/sales/return/:id?',
    component: () => import('@/modules/Admin/Sales/ReturnSales'),
    name: 'admin.returnSales',
    meta: {
      auth: true,
      middleware: 'Sales'
    },
  },

  //Services Sales
  {
    path: '/admin/sales/services/:id?',
    component: () => import('@/modules/Admin/Sales/ServiceSales'),
    name: 'admin.serviceSales',
    meta: {
      auth: true,
      middleware: 'Sales'
    },
  },

  //Credit Note
  {
    path: '/admin/sales/creditnote/:id?',
    component: () => import('@/modules/Admin/Sales/CreditNote'),
    name: 'admin.creditNote',
    meta: {
      auth: true,
      middleware: 'Sales'
    },
  },

  //Reverse Load
  {
    path: '/admin/sales/reverseload/:id?',
    component: () => import('@/modules/Admin/Sales/ReverseLoad'),
    name: 'admin.salesReverseLoad',
    meta: {
      auth: true,
      middleware: 'Sales'
    },
  },

  //Transit Sales
  {
    path: '/admin/sales/transit/:id?',
    component: () => import('@/modules/Admin/Sales/TransitSales'),
    name: 'admin.transitSales',
    meta: {
      auth: true,
      middleware: 'Sales'
    },
  },

  //Other Sales
  {
    path: '/admin/sales/other_sales/:id?',
    component: () => import('@/modules/Admin/Sales/OtherSales'),
    name: 'admin.otherSales',
    meta: {
      auth: true,
      middleware: 'Sales'
    },
  },

  //Book Sales
  {
    path: '/admin/sales/book_sales',
    component: () => import('@/modules/Admin/Sales/SalesBook'),
    name: 'admin.salesBook',
    meta: {
      auth: true,
      middleware: 'Sales'
    },
  },

  //Diary Book
  {
    path: '/admin/sales/diary_book',
    component: () => import('@/modules/Admin/Sales/DiaryBook'),
    name: 'admin.diaryBook',
    meta: {
      auth: true,
      middleware: 'Sales'
    },
  },

  //Transaction Book
  {
    path: '/admin/sales/transaction_book',
    component: () => import('@/modules/Admin/Sales/TransactionBook'),
    name: 'admin.transactionBook',
    meta: {
      auth: true,
      middleware: 'Sales'
    },
  },

  //Incoming Payment
  {
    path: '/admin/payment/incoming',
    component: () => import('@/modules/Admin/Payment/IncomingPayment'),
    name: 'admin.incomingPayment',
    meta: {
      auth: true,
      middleware: 'Payment'
    },
  },


  {
    path: '/admin/payment/incoming/:id',
    component: () => import('@/modules/Admin/Payment/IncomingPayment'),
    name: 'admin.incomingPaymentEdit',
    meta: {
      auth: true,
      middleware: 'Payment'
    },
  },

  //Advances Applied
  {
    path: '/admin/payment/advances_applied',
    component: () => import('@/modules/Admin/Payment/AdvancesApplied'),
    name: 'admin.advancesApplied',
    meta: {
      auth: true,
      middleware: 'Payment'
    },
  },
 


  {
    path: '/admin/payment/advances_applied/:id',
    component: () => import('@/modules/Admin/Payment/AdvancesApplied'),
    name: 'admin.advancesAppliedEdit',
    meta: {
      auth: true,
      middleware: 'Payment'
    },
  },

  //Other Incoming Payment
  {
    path: '/admin/payment/other_incoming',
    component: () => import('@/modules/Admin/Payment/OtherIncomingPayment'),
    name: 'admin.otherIncomingPayment',
    meta: {
      auth: true,
      middleware: 'Payment'
    },
  },

  {
    path: '/admin/payment/other_incoming/:id',
    component: () => import('@/modules/Admin/Payment/OtherIncomingPayment'),
    name: 'admin.otherIncomingPaymentEdit',
    meta: {
      auth: true,
      middleware: 'Payment'
    },
  },

  //Extract Registration
  {
    path: '/admin/payment/extract_registration',
    component: () => import('@/modules/Admin/Payment/ExtractRegistration'),
    name: 'admin.extractRegistration',
    meta: {
      auth: true,
      middleware: 'Payment'
    },
  },

  {
    path: '/admin/payment/extract_registration/:id',
    component: () => import('@/modules/Admin/Payment/ExtractRegistration'),
    name: 'admin.extractRegistrationEdit',
    meta: {
      auth: true,
      middleware: 'Payment'
    },
  },

  //Funds Transfer
  {
    path: '/admin/payment/funds_transfer',
    component: () => import('@/modules/Admin/Payment/FundsTransfer'),
    name: 'admin.fundsTransfer',
    meta: {
      auth: true,
      middleware: 'Payment'
    },
  },

  {
    path: '/admin/payment/funds_transfer/:id',
    component: () => import('@/modules/Admin/Payment/FundsTransfer'),
    name: 'admin.fundsTransferEdit',
    meta: {
      auth: true,
      middleware: 'Payment'
    },
  },

  //Outgoing
  {
    path: '/admin/payment/outgoing',
    component: () => import('@/modules/Admin/Payment/OutgoingPayment'),
    name: 'admin.outgoingPayment',
    meta: {
      auth: true,
      middleware: 'Payment'
    },
  },

  {
    path: '/admin/payment/outgoing/:id?',
    component: () => import('@/modules/Admin/Payment/OutgoingPayment'),
    name: 'admin.outgoingPaymentEdit',
    meta: {
      auth: true,
      middleware: 'Payment'
    },
  },

  //Iutgoing  Advance
  {
    path: '/admin/payment/outgoing_advance',
    component: () => import('@/modules/Admin/Payment/OutgoingAdvance'),
    name: 'admin.outgoingAdvance',
    meta: {
      auth: true,
      middleware: 'Payment'
    },
  },


  {
    path: '/admin/payment/outgoing_advance/:id?',
    component: () => import('@/modules/Admin/Payment/OutgoingAdvance'),
    name: 'admin.outgoingAdvanceEdit',
    meta: {
      auth: true,
      middleware: 'Payment'
    },
  },
  //Other Outgoing
  {
    path: '/admin/payment/other_outgoing',
    component: () => import('@/modules/Admin/Payment/OtherOutgoing'),
    name: 'admin.otherOutgoing',
    meta: {
      auth: true,
      middleware: 'Payment'
    },
  },

  {
    path: '/admin/payment/other_outgoing/:id?',
    component: () => import('@/modules/Admin/Payment/OtherOutgoing'),
    name: 'admin.otherOutgoingEdit',
    meta: {
      auth: true,
      middleware: 'Payment'
    },
  },

  //Compensations
  {
    path: '/admin/payment/compensations',
    component: () => import('@/modules/Admin/Payment/Compensations'),
    name: 'admin.compensations',
    meta: {
      auth: true,
      middleware: 'Payment'
    },
  },

  //Import Orders
  {
    path: '/admin/orders/import',
    component: () => import('@/modules/Admin/Orders/ImportPurchases'),
    name: 'admin.importOrders',
    meta: {
      auth: true,
      middleware: 'Orders'
    },
  },

  //Local Orders
  {
    path: '/admin/orders/local',
    component: () => import('@/modules/Admin/Orders/LocalPurchases'),
    name: 'admin.localOrders',
    meta: {
      auth: true,
      middleware: 'Orders'
    },
  },

  //Internal Orders
  {
    path: '/admin/orders/internal',
    component: () => import('@/modules/Admin/Orders/InternalOrder'),
    name: 'admin.internalOrder',
    meta: {
      auth: true,
      middleware: 'Orders'
    },
  },

  //Internal Movement
  {
    path: '/admin/orders/internal_movement',
    component: () => import('@/modules/Admin/Orders/InternalMovement'),
    name: 'admin.internalMovement',
    meta: {
      auth: true,
      middleware: 'Orders'
    },
  },

  {
    path: '/admin/orders/accountsPayable',
    component: () => import('@/modules/Admin/Orders/AccountsPayable'),
    name: 'admin.accountsPayable',
    meta: {
      auth: true,
      middleware: 'Orders'
    },
  },

  {
    path: '/admin/orders/accountsPayable/new',
    component: () => import('@/modules/Admin/Orders/AccountsPayable/Create'),
    name: 'admin.accountsPayableNew',
    meta: {
      auth: true,
      middleware: 'Orders'
    },
  },

  {
    path: '/admin/orders/accountsPayable/:id',
    component: () => import('@/modules/Admin/Orders/AccountsPayable/Create'),
    name: 'admin.accountsPayableEdit',
    meta: {
      auth: true,
      middleware: 'Orders'
    },
  },

  //Local Sales
  {
    path: '/admin/orders/local_sales',
    component: () => import('@/modules/Admin/Orders/LocalSales'),
    name: 'admin.orderslocalSales',
    meta: {
      auth: true,
      middleware: 'Orders'
    },
  },

  //Export Sales
  {
    path: '/admin/orders/export_sales',
    component: () => import('@/modules/Admin/Orders/ExportSales'),
    name: 'admin.orderexportSales',
    meta: {
      auth: true,
      middleware: 'Orders'
    },
  },

  //Ecommerce
  {
    path: '/admin/orders/ecommerce',
    component: () => import('@/modules/Admin/Orders/Ecommerce'),
    name: 'admin.orderEcommerce',
    meta: {
      auth: true,
      middleware: 'Orders'
    },
  },

  {
    path: '/admin/orders/ecommerce/track',
    component: () => import('@/modules/Admin/Orders/Ecommerce/Track.vue'),
    name: 'admin.orderEcommerceTrack',
    meta: {
      auth: true,
      middleware: 'Orders'
    },
  },

  {
    path: '/admin/orders/ecommerce/no-stock',
    component: () => import('@/modules/Admin/Orders/EcommerceNoStock'),
    name: 'admin.orderEcommerceMissingStock',
    meta: {
      auth: true,
      middleware: 'Orders'
    },
  },

  {
    path: '/admin/orders/ecommerce/invoice/:id',
    component: () => import('@/modules/Admin/Orders/Ecommerce/Invoice.vue'),
    name: 'admin.orderEcommerceInvoice',
    meta: {
      auth: true,
      middleware: 'Orders'
    },
  },
  // Print Shipping Label
  {
    path: '/admin/orders/ecommerce/printlabel/:id',
    component: () => import('@/modules/Admin/Orders/Ecommerce/ShippingLabel/index.vue'),
    name: 'admin.printShippingLabel',
    meta: {
      auth: true,
      middleware: 'Orders'
    },
  },

  {
    path: '/admin/orders/ecommerce/:id',
    component: () => import('@/modules/Admin/Orders/Ecommerce/Edit'),
    name: 'admin.orderEcommerceEdit',
    meta: {
      auth: true,
      middleware: 'Orders'
    },
  },

  //B2B Orders
  {
    path: '/admin/orders/btob',
    component: () => import('@/modules/Admin/Orders/B2B'),
    name: 'admin.b2bOrders',
    meta: {
      auth: true,
      middleware: 'Orders'
    },
  },

  //Company
  {
    path: '/admin/configurations/company',
    component: () => import('@/modules/Admin/Configurations/Company'),
    name: 'admin.company',
    meta: {
      auth: true,
    },
  },

  //General
  {
    path: '/admin/configurations/general',
    component: () => import('@/modules/Admin/Configurations/General'),
    name: 'admin.general',
    meta: {
      auth: true,
    },
  },

  //Countries
  {
    path: '/admin/configurations/countries',
    component: () => import('@/modules/Admin/Configurations/Countries'),
    name: 'admin.countries',
    meta: {
      auth: true,
    },
  },

  //Cities
  {
    path: '/admin/configurations/cities',
    component: () => import('@/modules/Admin/Configurations/Cities'),
    name: 'admin.cities',
    meta: {
      auth: true,
    },
  },

  //Themes
  {
    path: '/admin/configurations/themes',
    component: () => import('@/modules/Admin/Configurations/Themes'),
    name: 'admin.themes',
    meta: {
      auth: true,
    },
  },

  //Currencies
  {
    path: '/admin/configurations/currencies',
    component: () => import('@/modules/Admin/Configurations/Currencies'),
    name: 'admin.currencies',
    meta: {
      auth: true,
    },
  },

  //Articles
  {
    path: '/admin/configurations/articles',
    component: () => import('@/modules/Admin/Configurations/Articles'),
    name: 'admin.confArticles',
    meta: {
      auth: true,
    },
  },
  //Subjects
  {
    path: '/admin/configurations/subjects',
    component: () => import('@/modules/Admin/Configurations/Subjects'),
    name: 'admin.confSubjects',
    meta: {
      auth: true,
    },
  },

  //Purchases
  {
    path: '/admin/configurations/purchases',
    component: () => import('@/modules/Admin/Configurations/Purchases'),
    name: 'admin.confPurchases',
    meta: {
      auth: true,
    },
  },

  //Payments APIs
  {
    path: '/admin/configurations/payment_APIs',
    component: () => import('@/modules/Admin/Configurations/PaymentsAPIs'),
    name: 'admin.paymentsAPIs',
    meta: {
      auth: true,
    },
  },

  //Third Part APIs
  {
    path: '/admin/configurations/third_part_APIs',
    component: () => import('@/modules/Admin/Configurations/ThirdpartAPIs'),
    name: 'admin.thirdpartAPIs',
    meta: {
      auth: true,
    },
  },

  //Salary Calculation
  {
    path: '/admin/accoutingplan/salary_calculation',
    component: () => import('@/modules/Admin/AccountingPlan/SalaryCalculation'),
    name: 'admin.salaryCalculation',
    meta: {
      auth: true,
    },
  },
  {
    path: '/admin/accoutingplan/salary_calculation/:id',
    component: () => import('@/modules/Admin/AccountingPlan/SalaryCalculation'),
    name: 'admin.salaryCalculationEdit',
    meta: {
      auth: true,
    },
  },

  //Holidays
  {
    path: '/admin/accoutingplan/holidays',
    component: () => import('@/modules/Admin/AccountingPlan/Holidays'),
    name: 'admin.holidays',
    meta: {
      auth: true,
    },
  },

  //Holidays Types
  {
    path: '/admin/accoutingplan/holidays_types',
    component: () => import('@/modules/Admin/AccountingPlan/HolidayTypes'),
    name: 'admin.holidaysTypes',
    meta: {
      auth: true,
    },
  },

  //Initial Saldo
  {
    path: '/admin/accoutingplan/initial_saldo',
    component: () => import('@/modules/Admin/AccountingPlan/InitialSaldo'),
    name: 'admin.initialSaldo',
    meta: {
      auth: true,
    },
  },


   {
    path: '/admin/accoutingplan/initial_saldo/:id',
    component: () => import('@/modules/Admin/AccountingPlan/InitialSaldo'),
    name: 'admin.initialSaldoEdit',
    meta: {
      auth: true,
    },
  },

  //Financial Orders
  {
    path: '/admin/accoutingplan/financial_orders',
    component: () => import('@/modules/Admin/AccountingPlan/FinancialOrders'),
    name: 'admin.financialOrders',
    meta: {
      auth: true,
    },
  },
  {
    path: '/admin/accoutingplan/financial_orders/:id',
    component: () => import('@/modules/Admin/AccountingPlan/FinancialOrders'),
    name: 'admin.financialOrdersEdit',
    meta: {
      auth: true,
    },
  },

  //End Of Year Orders
  {
    path: '/admin/accoutingplan/end_of_year_orders',
    component: () => import('@/modules/Admin/AccountingPlan/EndOfYearOrders'),
    name: 'admin.endOfYearOrders',
    meta: {
      auth: true,
    },
  },

  {
    path: '/admin/accoutingplan/end_of_year_orders/:id',
    component: () => import('@/modules/Admin/AccountingPlan/EndOfYearOrders'),
    name: 'admin.endOfYearOrdersEdit',
    meta: {
      auth: true,
    },
  },

  //Stock Adjustment
  {
    path: '/admin/accoutingplan/stock_adjustment',
    component: () => import('@/modules/Admin/AccountingPlan/StockAdjustment'),
    name: 'admin.stockAdjustment',
    meta: {
      auth: true,
    },
  },


  {
    path: '/admin/accoutingplan/stock_adjustment/:id',
    component: () => import('@/modules/Admin/AccountingPlan/StockAdjustment'),
    name: 'admin.stockAdjustmentEdit',
    meta: {
      auth: true,
    },
  },

  //administrator
  {
    path: '/admin/administrator',
    component: () => import('@/modules/Admin/Administrator'),
    name: 'admin.administrator',
    meta: {
      auth: true,
    },
  },
  //users
  {
    path: '/admin/users',
    component: () => import('@/modules/Admin/Users/UsersTable'),
    name: 'admin.users',
    meta: {
      auth: true,
    },
  },

  //userstable
  {
    path: '/admin/users/new/:id?',
    component: () => import('@/modules/Admin/Users/CreateUser'),
    name: 'admin.users.new',
    meta: {
      auth: true,
    },
  },

  //Reports
  {
    path: '/admin/reports',
    component: () => import('@/modules/Admin/Reports'),
    name: 'admin.reports',
    meta: {
      auth: true,
    },
  },
  {
    path: '/admin/reports/detailsCertificate',
    component: () => import('@/modules/Admin/Reports/ContributionCertificate/DetailsContributionCertificate'),
    name: 'admin.detailsContributionCertificate',
    meta: {
      auth: true,
    },
  },
  {
    path: '/admin/reports/printForm',
    component: () => import('@/modules/Admin/Reports/FormularTVSH/PrintForm/PrintForm.vue'),
    name: 'admin.printForm',
    meta: {
      auth: true,
    },
  },
  {
    path: '/admin/reports/printCashFlow',
    component: () => import('@/modules/Admin/Reports/CashFlow/PrintCashFlow/PrintCashFlow.vue'),
    name: 'admin.printCashFlow',
    meta: {
      auth: true,
    },
  },
  {
    path: '/admin/reports/printIncomeState',
    component: () => import('@/modules/Admin/Reports/IncomeState/PrintIncomeState.vue'),
    name: 'admin.printIncomeState',
    meta: {
      auth: true,
    },
  },
  {
    path: '/admin/reports/printStateBalance',
    component: () => import('@/modules/Admin/Reports/StateBalance/PrintStateBalance.vue'),
    name: 'admin.printStateBalance',
    meta: {
      auth: true,
    },
  },
  {
    path: '/admin/reports/printOwnerEquity',
    component: () => import('@/modules/Admin/Reports/OwnersEquity/PrintOwnersEquity.vue'),
    name: 'admin.printOwnersEquity',
    meta: {
      auth: true,
    },
  },
  //banners
  {
    path: '/admin/banners',
    component: () => import('@/modules/Admin/Banners'),
    name: 'admin.banners',
    meta: {
      auth: true,
    },
  },
  //filemenager
  {
    path: '/admin/filemenager',
    component: () => import('@/modules/Admin/FileMenager'),
    name: 'admin.filemenager',
    meta: {
      auth: true,
    },
  },
  //TypeOfLoadsPage
  {
    path: '/admin/purchases/typeofloadspage',
    component: () => import('@/modules/Admin/Purchases/TypeOfLoadsImportPurchase'),
    name: 'admin.typeofloadspage',
    meta: {
      auth: true,
      middleware: 'Purchases'
    },
  },
  //TypeOfLoadsPageTwo
  {
    path: '/admin/purchases/TypeOfLoadsPageTwo',
    component: () => import('@/modules/Admin/Purchases/TypeOfLoadsLocalPurchase'),
    name: 'admin.TypeOfLoadsPageTwo',
    meta: {
      auth: true,
      middleware: 'Purchases'
    },
  },
  //OrdersLocalPurchasesNew
  {
    path: '/admin/OrdersLocalPurchases/New',
    component: () => import('@/modules/Admin/Orders/LocalPurchases/Add'),
    name: 'admin.ordersLocalPurchasesNew',
    meta: {
      auth: true,
      middleware: 'Orders'
    },
  },

  //ordersImportPurchasesNew
  {
    path: '/admin/OrdersImportPurchases/New',
    component: () => import('@/modules/Admin/Orders/ImportPurchases/Add'),
    name: 'admin.ordersImportPurchasesNew',
    meta: {
      auth: true,
      middleware: 'Orders'
    },
  },

  //ordersLocalSalesNew
  {
    path: '/admin/OrdersLocalSales/New',
    component: () => import('@/modules/Admin/Orders/LocalSales/Add'),
    name: 'admin.ordersLocalSalesNew',
    meta: {
      auth: true,
      middleware: 'Orders'
    },
  },

  //ordersInternalNew
  {
    path: '/admin/OrdersInternal/New',
    component: () => import('@/modules/Admin/Orders/InternalOrder/Add'),
    name: 'admin.ordersInternalNew',
    meta: {
      auth: true,
      middleware: 'Orders'
    },
  },

  //ordersInternalMovementNew
  {
    path: '/admin/OrdersInternalMovement/New',
    component: () => import('@/modules/Admin/Orders/InternalMovement/Add'),
    name: 'admin.ordersInternalMovementNew',
    meta: {
      auth: true,
      middleware: 'Orders'
    },
  },

  //ordersExportSalesNew
  {
    path: '/admin/OrdersExportSales/New',
    component: () => import('@/modules/Admin/Orders/ExportSales/Add'),
    name: 'admin.ordersExportSalesNew',
    meta: {
      auth: true,
      middleware: 'Orders'
    },
  },

  //Calendar
  {
    path: '/admin/calendar',
    component: () => import('@/modules/Admin/Calendar'),
    name: 'admin.calendar',
    meta: {
      auth: true,
      middleware: 'Calendar',
    },
  },
];
