import { createApp } from 'vue';
import App from './modules/App/index.vue';
import router from './router';
import store from './store';
import VueNotificationList from '@dafcoe/vue-notification';
import dayjs from 'dayjs';
import VueAwesomePaginate from "vue-awesome-paginate";
import { appConfig } from '@/config/app.js';
import httpAxios from '@/utils/http-axios';
import VCalendar from 'v-calendar';

import 'v-calendar/dist/style.css';
import "vue-awesome-paginate/dist/style.css";
import '@/assets/css/main.css';
import '@/assets/css/general.css';

import { modul } from "@/directives/modules.js"
import ExportExcel from '@/components/ExportExcel.vue';
import SearchComponent from '@/components/Search.vue';
import Pagination from '@/components/Pagination';

var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)

const app = createApp(App)
app.config.globalProperties.$dayjs = dayjs;
app.config.globalProperties.$site_key = appConfig.SITE_KEY;


let user = store.getters.getLoggedUser;

if(user) {
    let id = user.user.id;

    httpAxios({
        url: 'users/' + id,
        method: 'GET'
    }).then((response) => {
        store.dispatch('updateLoggedUser', response.data.data)
    })
}


app.directive('can', modul);
app.component('ExportExcel', ExportExcel)
app.component('SearchComponent', SearchComponent)
app.component('Pagination', Pagination)
app
    .use(router)
    .use(VCalendar, {})
    .use(store)
    .use(VueNotificationList)
    .use(VueAwesomePaginate)
    .use(dayjs).mount('#app');
