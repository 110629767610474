<template>
  <div>
    <button @click="search()">Search</button>
    <input type="text" @keyup.enter="search()" v-model="value" :placeholder="'Search ' + search_name + '...'" />
  </div>
</template>
<script>
import { ref } from '@vue/reactivity';
export default {
  props: ['search_name', 'column'],
  emits: ['filteredData'],
  setup(props, { emit }) {
    const value = ref(null);
    const search = () => {
        emit('filteredData', value.value, props.column, 'like');
    }

    return {
        value,
        search
    }
  },
};
</script>
<style scoped>
input {
  width: 260px !important;
  height: 40px !important;
  border-radius: 10px !important;
  background: #1d2225 !important;
  border: 1px solid #4d4d4d !important;
  font-size: 14px !important;
  color: white !important;
  padding-left: 20px !important;
}
button {
  width: 100px !important;
  height: 40px !important;
  background: #1d2225 !important;
  border-radius: 10px !important;
  border: 1px solid #4d4d4d !important;
  font-size: 14px !important;
  color: white !important;
  margin-right: 10px !important;
}
</style>
