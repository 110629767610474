const DISPLAY_LOADER = (state, display) => {
  state.displayLoader = display;
};

const LOGGED_USER = (state, user) => {
  let now = new Date();
  let expiryDate = new Date();
  user.expiryDate = expiryDate.setTime(now.getTime() + user.expires_in * 1000);

  localStorage.setItem('loggedUser', JSON.stringify(user));
  localStorage.setItem('modules', JSON.stringify(user.user.modules));
  state.loggedUser = JSON.stringify(user);
  state.modules = JSON.stringify(user.modules);
};

const REMOVE_LOGGED_USER = (state) => {
  state.loggedUser = null;
  localStorage.removeItem('loggedUser');
};

const UPDATE_LOGGED_USER = (state, userUpdated) => {
  let user = state.loggedUser;
  if (!user) {
    return;
  }
  user = JSON.parse(user);
  user.user = userUpdated;

  localStorage.setItem('loggedUser', JSON.stringify(user));
  localStorage.setItem('modules', JSON.stringify(user.user.modules));

  state.loggedUser = JSON.stringify(user);
};

const SELECTED_MODULES = (state, data) => {
  localStorage.setItem('selectedModules', JSON.stringify(data));
  state.selectedModules = JSON.stringify(data);
};

export default {
  DISPLAY_LOADER,
  LOGGED_USER,
  REMOVE_LOGGED_USER,
  UPDATE_LOGGED_USER,
  SELECTED_MODULES
};
