const getDisplayLoader = (state) => {
  return state.displayLoader;
};

const getLoggedUser = (state) => {
  let user = state.loggedUser;
  if (user) {
    user = JSON.parse(user);
  }
  return user;
};

const getModules = (state) => {
  return state.modules;
};

const getSelectedModules = (state) => {
  return state.selectedModules;
};

export default {
  getDisplayLoader,
  getLoggedUser,
  getSelectedModules,
  getModules
};
